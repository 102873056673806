import swal from 'sweetalert2';

export const funnelMixin = {
  data() {
    return {
      funnelFailed: false,
    }
  },
  methods: {
    goToNextFunnelStep(args) {
      const vm = this;
      if (vm.funnel) {
        const url = vm.$apiService.funnelsUrl();
        const data = {
          current_path: window.location.pathname,
        };
        if (args) {
          data.args = args;
        } else {
          data.args = { }
        }
        // This is a hack. See wizard.html.erb and shortcut story 289
        // Basically, we always render the user verify based on funnel state server side
        // so they can't get to this funnel step firing unless they did that.
        data.args.user_identity_verified = true;

        axios.put(url, data)
          .then((response) => {
            const { state } = response.data;
            window.location.assign(vm.urlForState(state));
          })
          .catch((error) => {
            vm.saving = false;
            vm.funnelFailed = true;
            vm.errorMessage = `Error going to the next step: ${error.toString()}`;
            vm.error = true;

            if (error.response?.status === 404) {
              this.handleNotFound();
            }
          });
      }
    },
    handleNotFound() {
      swal.fire({
        type: 'error',
        title: 'Your cart was cleared',
        html: "<p>This typically happens if registration closed before you complete sign up or if you completed/exited the registration on a different device or browser.</p><p>If you need assistance please email support@wrestlingiq.com</p>",
        confirmButtonClass: 'btn btn btn-default',
        confirmButtonText: 'Ok',
        buttonsStyling: false,
      })
        .then((result) => {
          window.location.assign('/calendar');
        });
    },
    urlForState(state) {
      switch (state) {
        case 'personal_info':
          return '/required-info';
        case 'add_wrestlers':
          return '/new-wrestler-wizard';
        case 'wrestler_info':
          return '/required-wrestler-info';
        case 'agreements':
          return '/agreements';
        case 'registration':
          return '/memberships/new';
        default:
          return '/calendar';
      }
    },
  },
};
